import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo"
import Latest from "../components/latest"
import Featured from "../components/featured"
import Leftcol from "../components/leftcol"
import Rightcol from "../components/rightcol"

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <Featured/>
      <div className="flex-grow lg:flex border-t-4 border-indigo-500 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto ">
        <div className="min-w-0 flex-1 bg-white xl:flex">
          <Leftcol/>
          <div className="bg-transparent lg:min-w-0 lg:flex-1">
            <div className="border-b border-t border-gray-200 p-2 xl:border-t-0">
              <div className=" items-center">
                <h1 className="text-lg font-medium">LATEST MANGA UPDATES</h1>
              </div>
            </div>

            <div className="pl-2 pr-2 pt-4 pb-4 xl:border-t-0  xl:pt-6" isHomePage>
              <div className="flex items-center">
                <Latest/>
              </div>
            </div>
          </div>
        </div>
        <Rightcol/>
      </div>
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
