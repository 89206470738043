import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Subscribe from "../components/subscribe"
import Newmanga from "../components/new"

const Leftcol = () => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="xl:w-64 xl:flex-shrink-0 xl:border-r xl:border-gray-200">
      <div className="py-6 pl-4 pr-4 sm:pl-6 lg:pl-2 xl:p-3">
        <div className="flex items-center justify-between">
          <div className="flex-1 space-y-8">
            <div className="space-y-8 sm:flex sm:items-center sm:justify-between sm:space-y-0 xl:block xl:space-y-8">
              <Subscribe title={title}/>
            </div>

            <div>
              <div className="pt-2 pb-2">
                <h2 className="text-sm font-semibold flex align-middle">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                </svg>
                  &nbsp;
                  New Manga
                </h2>
              </div>
              <div class="border-b-2 border-gray-300 mb-2"></div>
            </div>

            <Newmanga/>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Leftcol
