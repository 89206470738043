import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from 'react-elastic-carousel'

const Featured = () => {
  const data = useStaticQuery(
    graphql`
     query {
      allWpCategory(sort: { fields: name, order: DESC }) {
        nodes {
          name
          slug
          uri
          posts {
            nodes {
              title
              date(formatString: "MMMM DD, YYYY")
              uri
              featuredImage {
                node {
                  guid
                }
              }
            }
          }
        }
      }
     }
    `
   );
   
  const posts = data.allWpCategory.nodes
  const breakp = [
    { width: 1, itemsToShow: 1, pagination: false  },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 4, pagination: false },
    { width: 1150, itemsToShow: 7, itemsToScroll: 1, pagination: false },
  ]
  
  return (
    <div className=" mb-4" aria-label="Sidebar">
      <Carousel itemsToShow={7} enableAutoPlay autoPlaySpeed={2000} itemsToScroll={1} breakPoints={breakp}>
      {posts.map(function(item, i) {
        const title = item.name
        const catPosts = posts[i].posts.nodes

        const hasPosts = catPosts.map(function(cat, k) {
          return cat.title
        })

        const el = Array.from(catPosts)[0];
        const featuredImage = el?.featuredImage?.node?.guid
        const postDate = el?.date
        
        if (hasPosts.length) {
        return (
            <a
              key={item.name}
              href={item.uri}
              className='text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-1 py-2 text-xs rounded-md align-middle'
            >
              <div className="flex flex-1 flex-col p-2">
                <div className="flex-shrink-0">
                    {featuredImage
                      ? <img
                          src={featuredImage}
                          className="h-5/6 w-fit border border-gray-300 bg-white text-gray-300"
                          alt={title}
                        />
                      : 
                        <svg
                          className="h-5/6 w-fit border border-gray-300 bg-white text-gray-300"
                          preserveAspectRatio="none"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 200 200"
                          aria-hidden="true"
                        >
                          <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />
                        </svg>
                    }
                </div>
                <h3 className="mt-6 text-sm font-medium text-gray-900">{title}</h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dd className="text-xs text-gray-500">{postDate}</dd>
                  <dd className="mt-3">
                    <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                    {item.count}
                    </span>
                  </dd>
                </dl>
              </div>
            </a>
        )} else { return(<></>)}
      })}
      </Carousel>
    </div>
  )
}

export default Featured
