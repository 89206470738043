import React from "react"

const Subscribe = () => {
  return (
    <div className="bg-blue-100 shadow sm:rounded-lg">
      <div className="px-4 py-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Stay Stuned </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Subsribe to our newsletter and never miss out new release of your favorite Manga.</p>
        </div>
        <form className="mt-5 sm:items-center">
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="block w-full rounded-md border-gray-300 shadow-sm p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="you@example.com"
            />
          </div>
          <button
            type="submit"
            className="mt-3 w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  )
}

export default Subscribe
