import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ArrowRight from "../components/icon/arrow-right"

function truncate(str) {
  return str.length > 20 ? str.substring(0, 20) + "..." : str;
}

const Newmanga = () => {
  const data = useStaticQuery(
    graphql`
     query {
      allWpCategory(sort: { fields: name, order: DESC }, limit: 6) {
        nodes {
          name
          slug
          uri
          posts {
            nodes {
              title
              date(formatString: "MMMM DD, YYYY")
              uri
              featuredImage {
                node { 
                  guid
                }
              }
            }
          }
        }
      }
     }
    `
  );

  const posts = data.allWpCategory.nodes

  return (
    <>
    {posts.map(function(item, i) {
      const title = item.name
      const catPosts = posts[i].posts.nodes

      const hasPosts = catPosts.map(function(cat, k) {
        return cat.title
      })

      const el = Array.from(catPosts)[0];
      const featuredImage = el?.featuredImage?.node?.guid
      const postDate = el?.date

      const chapters = catPosts.slice(0, 3).map(cat => {
          return <div className="flex content-center"><ArrowRight/><a href={cat.uri} className='text-xs pl-1 block' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}> {truncate(cat.title)} </a></div>
      }); 
      
      if (hasPosts.length) {
        return (
          <div className="flex">
            <div className="mr-4 flex-shrink-0">
              {featuredImage
                ? <img
                    src={featuredImage}
                    className="h-full w-14 border border-gray-300 bg-white text-gray-300"
                    alt={title}
                  />
                : 
                  <svg
                    className="h-full w-14 border border-gray-300 bg-white text-gray-300"
                    preserveAspectRatio="none"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 200 200"
                    aria-hidden="true"
                  >
                    <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />
                  </svg>
              }
            </div>
            <div>
              <h4 className="text-xs font-bold">{title}</h4>
              <p className="text-xs font-light text-gray-400"><i>{postDate}</i></p>
              <p className="items-center py-1 content-center ">
                {chapters}
              </p>
            </div>
          </div>
        )
      } else { return(<></>)}
    })}
    </>
  )
}

export default Newmanga
